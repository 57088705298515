{
  "name": "patientus",
  "version": "4.4.403",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration=local --proxy-config=proxy-local.conf.json --host 0.0.0.0 --disable-host-check",
    "start:mac": "ng serve --configuration=local --proxy-config=proxy-local-mac.conf.json",
    "start:linux": "ng serve --configuration=local --proxy-config=proxy-local-linux.conf.json",
    "start-4sigma": "ng serve --host 4sigma.localhost --configuration=local --proxy-config=proxy-local.conf.json",
    "start-4sigma:linux": "ng serve --host 4sigma.localhost --configuration=local --proxy-config=proxy-local-linux.conf.json",
    "start:ssl": "ng serve --ssl true --ssl-key ssl/server.key --ssl-cert ssl/server.crt --host=0.0.0.0 --proxy-config=proxy-local.conf.json --disable-host-check",
    "start:staging": "ng serve --configuration=staging --proxy-config=proxy-local.conf.json",
    "build": "ng build --configuration production",
    "build:staging": "ng build --configuration staging",
    "build:preview": "ng build --configuration preview",
    "test": "ng test --source-map=false --watch=false || true",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "compare-translation": "node compare-translation.js",
    "backend": "node json-server.js",
    "backend-401": "node json-serve-401.js",
    "postinstall": "node version-date.js &&  node moment-angular-cli-patch.js",
    "postversion": "echo 'all fine'"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/localize": "^18.2.13",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@apollo/client": "^3.5.8",
    "@babel/compat-data": "^7.9.6",
    "@ludovicm67/lib-filetransfer": "^1.0.3",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "@ngrx/component-store": "^18.1.1",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/entity": "^18.1.1",
    "@ngrx/operators": "^18.0.0",
    "@ngrx/router-store": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngrx/store-devtools": "^18.1.1",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^8.0.0",
    "@opentok/client": "^2.28.4",
    "@patientus/video-lib-angular": "^6.0.1",
    "@popperjs/core": "^2.11.2",
    "@pqina/angular-pintura": "^8.57.9",
    "@pqina/pintura": "^8.88.3",
    "@types/webrtc": "0.0.22",
    "@vonage/ml-transformers": "^5.1.3",
    "angular-file-saver": "^1.1.3",
    "angular2-draggable": "^16.0.0",
    "angular2-multiselect-dropdown": "^9.0.0",
    "apollo-angular": "^7.2.1",
    "blob": "0.1.0",
    "bootstrap": "5.3.2",
    "detect-browser": "^5.1.0",
    "font-awesome": "^4.7.0",
    "graphql": "^15.8.0",
    "graphql-tag": "^2.12.6",
    "jquery": "^3.2.1",
    "mime-types": "^2.1.28",
    "moment": "^2.30.1",
    "ngrx-store-localstorage": "^18.0.0",
    "ngx-chips": "^3.0.0",
    "ngx-clipboard": "^16.0.0",
    "ngx-cookie": "^6.0.1",
    "ngx-permissions": "^17.1.0",
    "opentok-layout-js": "^5.4.0",
    "opentok-network-test-js": "^2.5.0",
    "path": "^0.12.7",
    "process-nextick-args": "^2.0.1",
    "rxjs": "~7.8.1",
    "simple-peer": "^9.6.0",
    "tether": "^1.4.3",
    "tslib": "^2.7.0",
    "ua-parser-js": "^0.7.21",
    "webrtc-adapter": "^7.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "^18.4.2",
    "@angular-eslint/eslint-plugin": "^18.4.2",
    "@angular-eslint/eslint-plugin-template": "^18.4.2",
    "@angular-eslint/schematics": "^18.4.2",
    "@angular-eslint/template-parser": "^18.4.2",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@angular/language-service": "^18.2.13",
    "@ngrx/schematics": "^18.1.1",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/node": "^22.5.5",
    "@types/sinon": "^4.1.2",
    "@typescript-eslint/eslint-plugin": "^8.15.0",
    "@typescript-eslint/parser": "8.15.0",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-jsdoc": "^50.6.0",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "faker": "^5.5.3",
    "fs-extra": "^10.0.0",
    "globby": "^11.1.0",
    "jasmine-core": "~5.3.0",
    "jasmine-marbles": "^0.9.2",
    "jasmine-snapshot": "^1.8.1",
    "jasmine-spec-reporter": "~7.0.0",
    "json-server": "^0.12.1",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-junit-reporter": "^2.0.1",
    "karma-mocha-reporter": "^2.2.5",
    "lodash": "^4.17.20",
    "material-design-icons": "^3.0.1",
    "mock-socket": "^9.1.2",
    "moment-timezone": "^0.5.17",
    "node-fetch": "^2.6.7",
    "postcss": "^8.3.0",
    "protractor": "~7.0.0",
    "replace-in-file": "^4.1.0",
    "sinon": "^13.0.1",
    "store": "^2.0.12",
    "ts-node": "~10.5.0",
    "typescript": "^5.4.5"
  }
}
